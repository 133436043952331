import axios from "axios"
import * as D from "io-ts/Decoder"
import { pipe } from "fp-ts/lib/function"
import { config } from "@/config"
import { tryDecode } from "@/lib/decoders"
import { Client } from "./model/client"
import { User } from "./model/user"

// -----------------------------------------------------------------------------
// Login
// -----------------------------------------------------------------------------

export interface Credentials {
  username: string
  password: string
}

const LoginResponse = pipe(
  D.struct({
    user: User,
    access_token: D.string,
  }),
  D.map(({ access_token, ...data }) => ({
    ...data,
    token: access_token,
  }))
)

export type LoginResponse = D.TypeOf<typeof LoginResponse>

export function token(
  { username, password }: Credentials,
  client: Client
): Promise<LoginResponse> {
  return axios
    .post(`oauth2/token`, {
      username,
      password,
      client_id: client.id,
      client_secret: client.secret,
      grant_type: "password",
    })
    .then((res) => res.data)
    .then(tryDecode(LoginResponse))
}

// -----------------------------------------------------------------------------
// Register
// -----------------------------------------------------------------------------

export interface RegisterData {
  firstName?: string
  lastName?: string
  phone?: string
  username: string
  password: string
  company: {
    name: string
  }
  verificationUrl?: string
  appCode?: string
}

const defaultVerificationUrl = () =>
  `${window.location.origin}/verify-email?token={token}`

export function register(
  {
    verificationUrl,
    appCode = config.appCode,
    username,
    ...data
  }: RegisterData,
  client: Client
): Promise<User> {
  return axios
    .post(`register`, {
      client_id: client.id,
      client_secret: client.secret,
      email: username,
      verificationUrl: verificationUrl ?? defaultVerificationUrl(),
      appCode,
      ...data,
    })
    .then((res) => res.data)
    .then(tryDecode(User))
}

// -----------------------------------------------------------------------------
// Verify
// -----------------------------------------------------------------------------

export interface VerifyEmailData {
  token: string
}

const VerifyEmailResponse = D.struct({
  email: D.string,
})

export type VerifyEmailResponse = D.TypeOf<typeof VerifyEmailResponse>

export function verifyEmail({
  token,
}: VerifyEmailData): Promise<VerifyEmailResponse> {
  return axios
    .post(`verify-email-token/${token}`)
    .then((res) => res.data)
    .then(tryDecode(VerifyEmailResponse))
}
