import { pipe } from "fp-ts/lib/function"
import { isString } from "fp-ts/lib/string"
import * as D from "io-ts/Decoder"

// -----------------------------------------------------------------------------
// Type
// -----------------------------------------------------------------------------

const pattern =
  /^([a-f0-9]{8}-[a-f0-9]{4}-[1-5][a-f0-9]{3}-[89ab][a-f0-9]{3}-[a-f0-9]{12}|00000000-0000-0000-0000-000000000000)$/i

export type Uuid = string & { readonly Uuid: unique symbol }

export const Uuid = pipe(
  D.string,
  D.refine((value): value is Uuid => pattern.test(value), "Uuid")
)

// -----------------------------------------------------------------------------
// Functions
// -----------------------------------------------------------------------------

export function isUuid(value: unknown): value is Uuid {
  return isString(value) && pattern.test(value)
}

export const whereId =
  <T extends { id: Uuid }>(id: Uuid) =>
  (item: T) =>
    id === item.id
