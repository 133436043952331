import * as D from "io-ts/Decoder"
import { pipe } from "fp-ts/lib/function"
import { isEmpty } from "fp-ts/lib/ReadonlyRecord"

/**
 * This is workaround for API bug which causes that empty objects are
 * serialized as empty arrays, which breaks our decoders. This decoder
 * fixes this problem by translating empty array to `null`.
 */
export const Optional = <O extends Record<string, unknown>>(
  decoder: D.Decoder<unknown, O>
): D.Decoder<unknown, O | null> =>
  pipe(
    D.union(D.UnknownArray, D.UnknownRecord, D.literal(null)),
    D.parse((value) => {
      if (value === null || Array.isArray(value) || isEmpty(value)) {
        return D.success(null)
      }

      return decoder.decode(value)
    })
  )
