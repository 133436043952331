import * as D from "io-ts/Decoder"
import { isLeft } from "fp-ts/lib/Either"

export class ParsingError extends Error {}

/**
 * io-ts `Decoder#decode` does not throw on error and returns `Either`
 * instead. This method returns `right` (successful) result and throws
 * error on `left` (failed) result, which is in tune with traditional
 * error handling in JavaScript
 */
export const tryDecode =
  <A>(decoder: D.Decoder<unknown, A>) =>
  (value: unknown): A => {
    const result = decoder.decode(value)

    if (isLeft(result)) {
      throw new ParsingError(D.draw(result.left))
    }

    return result.right
  }
