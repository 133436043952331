import { config } from "@/config"
import axios from "axios"
import { storage } from "@/domain/auth"
import { isAxiosError } from "@/lib/error"
import { identity } from "fp-ts/lib/function"
import router from "next/router"

axios.defaults.baseURL = `${config.apiUrl}/api`

axios.interceptors.request.use((config) => {
  /* eslint-disable-next-line effector/no-getState */
  const session = storage.get()

  if (session && session.type === "authenticated") {
    config.headers["Authorization"] = `Bearer ${session.token}`
  }

  return config
})

axios.interceptors.response.use(identity, (error) => {
  // TODO: move this logic to same place as logout action
  // currently it's not possible since axios does not
  // have access to react context.
  if (isAxiosError(error) && error.response?.status === 401) {
    storage.reset()
    router.replace("/login")
  }

  return Promise.reject(error)
})
