import React from "react"
import { Alert, IconButton, Snackbar } from "@mui/material"
import { Close as CloseIcon } from "@mui/icons-material"
import { useSnackbar } from "."

export function SnackbarView() {
  const { state, close } = useSnackbar()

  if (state.type === "initial") {
    return null
  }

  const { severity, timeout, message } = state.snackbar
  const defaultTimeout = 5000

  return (
    <Snackbar
      open={state.type === "open"}
      autoHideDuration={
        timeout === "auto"
          ? defaultTimeout
          : timeout === "persist"
          ? null
          : timeout
      }
      onClose={() => close()}
    >
      <Alert
        severity={severity}
        variant="filled"
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => close()}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      >
        {message}
      </Alert>
    </Snackbar>
  )
}
