import * as D from "io-ts/Decoder"
import { z } from "zod"
import { UploadedFile } from "@/domain/file"
import { Place } from "@/domain/place"
import { Uuid } from "@/lib/uuid"
import { PhoneForm } from "@/lib/phone"
import { Optional, OptionalString } from "@/lib/decoders"
import { ContactPerson } from "./contact-person"

// -----------------------------------------------------------------------------
// Model
// -----------------------------------------------------------------------------

export const Company = D.struct({
  id: Uuid,
  name: D.string,
  contactPerson: Optional(ContactPerson),
  logo: Optional(UploadedFile),
  location: Optional(Place),
})

export type Company = D.TypeOf<typeof Company>

export const CompanyFormSchema = z.object({
  name: z.string().min(1),
  location: z.any(), // TODO
  logo: z.object({
    id: z.string().uuid(),
  }),
  contactPerson: z.object({
    firstName: z.string().optional(),
    lastName: z.string().optional(),
    email: OptionalString(z.string().email()),
    phone: OptionalString(PhoneForm),
  }),
})
