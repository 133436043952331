import { createContext, ReactNode } from "react"
import { AlertColor } from "@mui/material"
import { useState } from "react"
import { useContext } from "react"

// -----------------------------------------------------------------------------
// Types
// -----------------------------------------------------------------------------

type SnackbarState =
  | { type: "initial" }
  | { type: "open"; snackbar: Snackbar }
  | { type: "closed"; snackbar: Snackbar }

interface SnackbarRequiredConfig {
  message: string
}

interface SnackbarOptionalConfig {
  severity: AlertColor
  timeout: "auto" | "persist" | number
}

type Snackbar = SnackbarRequiredConfig & SnackbarOptionalConfig

type SnackbarConfig = SnackbarRequiredConfig & Partial<SnackbarOptionalConfig>

function createSnackbar({
  message,
  severity = "info",
  timeout = "auto",
}: SnackbarConfig): Snackbar {
  return { message, severity, timeout }
}

interface SnackbarContext {
  state: SnackbarState
  open: (config: SnackbarConfig) => void
  close: () => void
}

// -----------------------------------------------------------------------------
// Context
// -----------------------------------------------------------------------------

const SnackbarContext = createContext<SnackbarContext | null>(null)

export function SnackbarProvider({ children }: { children: ReactNode }) {
  const [state, setSnackbarState] = useState<SnackbarState>({
    type: "initial",
  })

  function open(config: SnackbarConfig) {
    setSnackbarState({
      type: "open",
      snackbar: createSnackbar(config),
    })
  }

  function close() {
    setSnackbarState(
      state.type === "open" ? { ...state, type: "closed" } : state
    )
  }

  return (
    <SnackbarContext.Provider value={{ state, open, close }}>
      {children}
    </SnackbarContext.Provider>
  )
}

export function useSnackbar(): SnackbarContext {
  const context = useContext(SnackbarContext)

  if (!context) {
    throw new Error("`useSnackbar` must be used in SnackbarProvider subtree")
  }

  return context
}
