import * as D from "io-ts/Decoder"

export const ContactPerson = D.partial({
  firstName: D.string,
  lastName: D.string,
  email: D.string,
  phone: D.string,
})

export type ContactPerson = D.TypeOf<typeof ContactPerson>
