import { useMutation } from "react-query"
import { useSnackbar } from "@/domain/snackbar"
import { displayError } from "@/lib/error"
import * as Api from "./api"

export function useFileUpload() {
  const snackbar = useSnackbar()

  return useMutation(Api.upload, {
    onError(error) {
      snackbar.open({
        severity: "error",
        message: displayError(error),
      })
    },
  })
}
