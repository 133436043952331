import * as D from "io-ts/Decoder"
import { Company } from "@/domain/company"
import { Demand } from "@/domain/demand"
import { Optional } from "@/lib/decoders"
import { Uuid } from "@/lib/uuid"

// -----------------------------------------------------------------------------
// Model
// -----------------------------------------------------------------------------

export const User = D.struct({
  id: Uuid,
  firstName: D.nullable(D.string),
  lastName: D.nullable(D.string),
  email: D.nullable(D.string),
  company: Optional(Company),
})

export type User = D.TypeOf<typeof User>

// -----------------------------------------------------------------------------
// Helpers
// -----------------------------------------------------------------------------

export const owns =
  (demand: Demand) =>
  (user: User | null): boolean =>
    !!user?.company &&
    !!demand.user.company &&
    user?.company?.id === demand.user.company?.id
