import { useMutation, useQueryClient } from "react-query"
import { useSnackbar } from "@/domain/snackbar"
import * as Api from "./api"

// -----------------------------------------------------------------------------
// Mutations
// -----------------------------------------------------------------------------

export function useUpdateCompany() {
  const client = useQueryClient()
  const snackbar = useSnackbar()

  return useMutation(Api.patch, {
    onSuccess() {
      client.invalidateQueries("me")
      snackbar.open({
        severity: "success",
        message: "Údaje o organizaci byly aktualizovány",
      })
    },
  })
}
