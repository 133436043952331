import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react"
import { useMutation, useQueryClient } from "react-query"
import { useRouter } from "next/router"
import { pipe } from "fp-ts/lib/function"
import * as D from "io-ts/Decoder"
import { config } from "@/config"
import { Uuid } from "@/lib/uuid"
import { tryDecode } from "@/lib/decoders"
import { useSnackbar } from "@/domain/snackbar"
import { displayError, isAxiosError } from "@/lib/error"
import * as Api from "./api"

// -----------------------------------------------------------------------------
// Types
// -----------------------------------------------------------------------------

type Guest = { type: "guest" }

type Authenticated = {
  type: "authenticated"
  token: string
  userId: Uuid
  companyId: Uuid | null
}

export type Session = Guest | Authenticated

interface SessionContext {
  session: Session
  authenticate: (res: Api.LoginResponse) => void
  reset: () => void
}

const Session: D.Decoder<unknown, Session> = D.sum("type")({
  guest: D.struct({ type: D.literal("guest") }),
  authenticated: D.struct({
    type: D.literal("authenticated"),
    token: D.string,
    userId: Uuid,
    companyId: D.nullable(Uuid),
  }),
})

// -----------------------------------------------------------------------------
// Storage
// -----------------------------------------------------------------------------

export const storage = {
  set(session: Session) {
    sessionStorage.setItem("session", JSON.stringify(session))
  },

  get(): Session | null {
    const value = sessionStorage.getItem("session")

    if (value) {
      return pipe(JSON.parse(value), tryDecode(Session))
    }

    return null
  },

  reset(): void {
    sessionStorage.removeItem("session")
  },
}

// -----------------------------------------------------------------------------
// Context
// -----------------------------------------------------------------------------

const SessionContext = createContext<SessionContext | null>(null)

const initialSession: Guest = {
  type: "guest",
}

export function SessionProvider({ children }: { children: ReactNode }) {
  const [session, setSession] = useState<Session>(
    storage.get() ?? initialSession
  )

  function authenticate({ token, user }: Api.LoginResponse) {
    setSession({
      type: "authenticated",
      token,
      userId: user.id,
      companyId: user.company?.id ?? null,
    })
  }

  function reset() {
    setSession(initialSession)
  }

  useEffect(() => {
    storage.set(session)
  }, [session])

  return (
    <SessionContext.Provider value={{ session, authenticate, reset }}>
      {children}
    </SessionContext.Provider>
  )
}

function useSessionContext(): SessionContext {
  const context = useContext(SessionContext)

  if (!context) {
    throw new Error("`useSession` must be used in SessionProvider subtree")
  }

  return context
}

// -----------------------------------------------------------------------------
// Hooks
// -----------------------------------------------------------------------------

export function useSession(): Session {
  const { session } = useSessionContext()
  return session
}

export function useMyUserId(): Uuid | null {
  const session = useSession()
  return session.type === "authenticated" ? session.userId : null
}

export function useMyCompanyId(): Uuid | null {
  const session = useSession()
  return session.type === "authenticated" ? session.companyId : null
}

// -----------------------------------------------------------------------------
// Mutations
// -----------------------------------------------------------------------------

export function useLogin() {
  const snackbar = useSnackbar()
  const router = useRouter()
  const { authenticate } = useSessionContext()

  return useMutation(
    (creds: Api.Credentials) => Api.token(creds, config.client),
    {
      onError(error) {
        // Report everything except 403 (user not verified) and 404 (wrong credentials)
        // status codes since they have their own dedicated error handling.
        if (
          !isAxiosError(error) ||
          (error.response?.status !== 403 && error.response?.status !== 404)
        ) {
          snackbar.open({
            severity: "error",
            message: displayError(error),
          })
        }
      },
      onSuccess(response) {
        authenticate(response)
        router.replace("/dashboard")
      },
    }
  )
}

export function useLogout() {
  const router = useRouter()
  const session = useSessionContext()
  const client = useQueryClient()

  return () => {
    session.reset()
    router.replace("/login")
    client.invalidateQueries()
  }
}
