import React from "react"
import { Box, Button, CircularProgress } from "@mui/material"
import { CloudUploadOutlined } from "@mui/icons-material"

interface MediaInputProps {
  label?: string
  loading?: boolean
  onChange: (file: File) => void
}

export function MediaInput({
  label = "Nahrát soubor",
  loading = false,
  onChange,
}: MediaInputProps) {
  const inputRef = React.useRef<HTMLInputElement | null>(null)

  function handleSelect() {
    inputRef.current?.click()
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.target.files?.[0]

    if (file) {
      onChange(file)
    }
  }

  return (
    <Box>
      <input hidden type="file" ref={inputRef} onChange={handleInputChange} />
      <Button startIcon={<CloudUploadOutlined />} onClick={handleSelect}>
        {label}
      </Button>
      {loading && <CircularProgress />}
    </Box>
  )
}
