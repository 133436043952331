import React from "react"
import { Avatar } from "@mui/material"

export function CompanyAvatar({
  company,
  size,
}: {
  company: { name: string; logo: { url: string } | null }
  size?: string | number
}) {
  return (
    <Avatar
      sx={{ width: size, height: size }}
      variant="rounded"
      src={company.logo?.url ?? undefined}
    >
      {company.name[0]}
    </Avatar>
  )
}
