import { AxiosError } from "axios"

// -----------------------------------------------------------------------------
// Types
// -----------------------------------------------------------------------------

export class NotFoundError extends Error {}

// -----------------------------------------------------------------------------
// Functions
// -----------------------------------------------------------------------------

export function isAxiosError(error: unknown): error is AxiosError {
  return typeof error === "object" && error !== null && "isAxiosError" in error
}

/**
 * Map `AxiosError` to user-friendly string.
 */
function displayAxiosError(error: AxiosError): string {
  if (error.response) {
    return (
      {
        [401]: "K této akci musíte být přihlášeni",
        [403]: "K této akci nemáte dostatečná oprávnění",
        [404]: "Nenalezeno",
        [500]: "Něco se pokazilo na serveru",
      }[error.response.status] ?? "Něco se pokazilo"
    )
  }

  if (error.request) {
    return "Nepodařilo se nám připojit k serveru. Jste připojeni k internetu?"
  }

  return "Něco se pokazilo"
}

/**
 * Map error to user-friendly string that can be displayed in UI.
 *
 * - If HTTP request failed, error will be instance of `AxiosError`
 * - If HTTP response validation failed, error will be instance of `ZodError`
 *
 * Return only messages that are relevant to users (like bad internet connection,
 * wrong credentials, insufficient privileges etc.). Avoid returning messages
 * that contain unneccessary technical details. Such details should be logged
 * to external service (ie. sentry) and not displayed to users.
 */
export function displayError(error: unknown): string {
  if (isAxiosError(error)) {
    return displayAxiosError(error)
  }

  if (error instanceof NotFoundError) {
    return "Nenalezeno"
  }

  // Throwed value can be anything, so we have to supply fallback message.
  return "Něco se pokazilo"
}
