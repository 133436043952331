import { Place } from "@/domain/place"
import { ContactPerson } from "./model/contact-person"
import { Uuid } from "@/lib/uuid"
import { Company } from "."
import axios from "axios"
import { tryDecode } from "@/lib/decoders"

export interface CompanyPatch {
  id: Uuid
  data: CompanyPatchData
}

export interface CompanyPatchData {
  name?: string
  location?: Place
  logo?: { id: Uuid }
  contactPerson?: ContactPerson
}

export function patch({ id, data }: CompanyPatch): Promise<Company> {
  return axios
    .patch(`/companies/${id}`, data)
    .then((res) => res.data)
    .then(tryDecode(Company))
}
