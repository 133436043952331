import { config } from "@/config"
import { displayError, isAxiosError } from "@/lib/error"
import { useSnackbar } from "@/domain/snackbar"
import { useRouter } from "next/router"
import { useMutation } from "react-query"
import * as Api from "./api"

export function useRegister() {
  const snackbar = useSnackbar()
  const router = useRouter()

  return useMutation(
    (data: Api.RegisterData) => Api.register(data, config.client),
    {
      onError(error) {
        // Don't report 409 error. We will show "user already exists" alert instead.
        if (!isAxiosError(error) || error.response?.status !== 409) {
          snackbar.open({
            severity: "error",
            message: displayError(error),
          })
        }
      },
      onSuccess() {
        router.replace("/signup/email-sent")
      },
    }
  )
}

export function useVerifyEmail() {
  const snackbar = useSnackbar()
  const router = useRouter()

  return useMutation(Api.verifyEmail, {
    onError(error) {
      snackbar.open({
        severity: "error",
        message: displayError(error),
      })
    },
    onSuccess({ email }) {
      router.replace(`/login?verified&email=${encodeURIComponent(email)}`)
    },
  })
}
