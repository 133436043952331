import type { AppProps } from "next/app"
import React from "react"
import { ThemeProvider } from "@mui/material/styles"
import { CssBaseline, GlobalStyles, NoSsr } from "@mui/material"
import { LocalizationProvider } from "@mui/lab"
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import { QueryClient, QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"
import { cs } from "date-fns/locale"
import { theme } from "@/theme"
import { RoleProvider } from "@/domain/role"
import { SnackbarProvider } from "@/domain/snackbar"
import { SessionProvider } from "@/domain/auth"

import "@/init"

const globalStyles = {
  ":root": {
    "--shadow-key-umbra-opacity": 0.08,
    "--shadow-key-penumbra-opacity": 0.05,
    "--shadow-key-ambient-opacity": 0.04,
  },
  a: {
    color: "inherit",
    textDecoration: "none",
  },
  "ul, ol": {
    margin: 0,
    padding: 0,
    textIndent: 0,
    listStyle: "none",
  },
  ".list-disc": {
    listStyle: "disc",
    marginLeft: "1em",
  },
  ".list-lower-alpha": {
    listStyle: "lower-alpha",
    marginLeft: "1em",
  },
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Make all loaded data stale after 10 seconds
      staleTime: 10 * 1000,
    },
  },
})

function App({ Component, pageProps }: AppProps) {
  return (
    <NoSsr>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={cs}>
            <SessionProvider>
              <SnackbarProvider>
                <RoleProvider>
                  <CssBaseline />
                  <GlobalStyles styles={globalStyles} />
                  <Component {...pageProps} />
                </RoleProvider>
              </SnackbarProvider>
            </SessionProvider>
          </LocalizationProvider>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </ThemeProvider>
    </NoSsr>
  )
}

export default App
