function required<T>(stuff: T | undefined): T {
  if (!stuff) {
    throw new Error("Required environment variable is not defined")
  }

  return stuff
}

export const config = {
  appName: process.env.NEXT_PUBLIC_APP_NAME ?? "Life Experience Exchange",
  appCode: process.env.NEXT_PUBLIC_APP_CODE ?? "sourcepanda",
  apiUrl: required(process.env.NEXT_PUBLIC_API_URL),
  client: {
    id: required(process.env.NEXT_PUBLIC_CLIENT_ID),
    secret: required(process.env.NEXT_PUBLIC_CLIENT_SECRET),
  },
} as const
