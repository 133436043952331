import { PlaceSuggestion } from "@/domain/maps"
import { pipe } from "fp-ts/lib/function"
import * as D from "io-ts/Decoder"

const Rectangle = D.tuple(D.number, D.number, D.number, D.number)

export const Place = D.struct({
  boundingBox: Rectangle,
  country: D.string,
  district: D.string,
  evidenceNumber: D.string,
  houseNumber: D.string, // Číslo popisné
  latitude: D.number,
  longitude: D.number,
  municipality: D.string,
  nuts: D.string,
  quarter: D.string,
  region: D.string,
  streetNumber: D.string, // Číslo orientační
  ward: D.string,
  zipCode: D.string,
  displayText: pipe(
    D.struct({
      primary: D.string,
    }),
    D.intersect(
      D.partial({
        secondary: D.string,
        tertiary: D.string,
      })
    )
  ),
  mapyCz: D.struct({
    id: D.number,
    municipalityId: D.string,
  }),
})

export type Place = D.TypeOf<typeof Place>

export function fromSuggestion({
  id,
  muniId,
  highlight: _highlight, // ignore
  highlightSecond: _highlightSecond, // ignore
  ...suggestion
}: PlaceSuggestion): Place {
  return {
    ...suggestion,
    mapyCz: {
      id,
      municipalityId: muniId,
    },
  }
}
