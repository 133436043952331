import * as D from "io-ts/Decoder"
import { z } from "zod"
import { config } from "@/config"
import { Uuid } from "../../lib/uuid"
import { pipe } from "fp-ts/lib/function"

export const UploadedFile = pipe(
  D.struct({
    id: Uuid,
    path: D.string,
  }),
  D.map((file) => ({
    ...file,
    url: `${config.apiUrl}${file.path}`,
  }))
)

export type UploadedFile = D.TypeOf<typeof UploadedFile>

export const UploadedFileForm = z.object({
  id: z.string().uuid(),
  path: z.string(),
})

export type UploadedFileForm = z.TypeOf<typeof UploadedFileForm>
