import axios from "axios"
import { UploadedFile } from "."
import { tryDecode } from "@/lib/decoders"

export function upload(file: File): Promise<UploadedFile> {
  const data = new FormData()
  data.append("upload", file)

  return axios
    .post(`files/upload`, data)
    .then((res) => res.data)
    .then(tryDecode(UploadedFile))
}
