import { createTheme, responsiveFontSizes } from "@mui/material"
import { shadows } from "./shadows"

const variant = "filled"

declare module "@mui/material/Button" {
  interface ButtonPropsSizeOverrides {
    xlarge: true
  }

  interface ButtonPropsVariantOverrides {
    ["contained-inverted"]: true
  }
}

const baseTheme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        main: "#2236c4",
        light: "#6472d6",
        "50": "#f4f5fc",
        "100": "#e9ebf9",
        "200": "#c8cdf0",
        "300": "#a7afe7",
        "400": "#6472d6",
        "500": "#2236c4",
        "600": "#1f31b0",
        "700": "#1a2993",
        "800": "#142076",
        "900": "#111a60",
      },
      secondary: {
        main: "#ffcd45",
      },
      info: {
        main: "#2236c4",
      },
      grey: {
        50: "#f9fafb",
        100: "#f3f4f6",
        200: "#e5e7eb",
        300: "#d1d5db",
        400: "#9ca3af",
        500: "#6b7280",
        600: "#4b5563",
        700: "#374151",
        800: "#1f2937",
        900: "#111827",
      },
      text: {
        primary: "rgba(17, 24, 39, 0.97)",
        secondary: "rgba(17, 24, 39, 0.6)",
        disabled: "rgba(17, 24, 39, 0.38)",
      },
      divider: "rgba(17, 24, 39, 0.16)",
      background: {
        paper: "#fff",
        default: "#fff",
      },
      action: {
        active: "rgba(17, 24, 39, 0.54)",
        hover: "rgba(17, 24, 39, 0.04)",
        selected: "rgba(17, 24, 39, 0.08)",
        disabled: "rgba(17, 24, 39, 0.26)",
        disabledBackground: "rgba(17, 24, 39, 0.12)",
        focus: "rgba(17, 24, 39, 0.12)",
      },
    },
    typography: {
      fontFamily: [
        "Public Sans",
        "ui-sans-serif",
        "system-ui",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        '"Noto Sans"',
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
        '"Noto Color Emoji"',
      ].join(", "),
      h1: { fontWeight: 400 },
      h2: { fontWeight: 400 },
      h3: { fontWeight: 400 },
      h4: { fontWeight: 400 },
      h5: { fontWeight: 400 },
      h6: { fontWeight: 400, lineHeight: 1.4 },
      body2: {
        fontWeight: 500,
        letterSpacing: "0.01em",
      },
      caption: {
        letterSpacing: "0.01em",
      },
    },
    shape: {
      borderRadius: 8,
    },
    shadows: shadows as any,
  })
)

export const theme = createTheme(
  {
    components: {
      MuiAlert: {
        styleOverrides: {
          root: {
            fontWeight: 600,
            padding: `1rem 1.5rem`,
          },
          standard: {
            border: "none",
          },
          filled: {
            border: "none",
          },
          standardInfo: {
            color: baseTheme.palette.info.main,
          },
        },
      },
      MuiAlertTitle: {
        styleOverrides: {
          root: {
            fontSize: 20,
            fontWeight: 700,
          },
        },
      },
      MuiAppBar: {
        defaultProps: {
          variant: "elevation",
          elevation: 3,
        },
        styleOverrides: {
          root: {
            border: "none",
          },
        },
      },
      MuiButton: {
        defaultProps: {
          disableElevation: true,
          size: "large",
        },
        styleOverrides: {
          root: {
            textTransform: "none",
            letterSpacing: 0,
            fontWeight: 600,
          },
          contained: {
            boxShadow: baseTheme.shadows[4],
            "&:hover": {
              boxShadow: baseTheme.shadows[2],
            },
          },
        },
        variants: [
          {
            props: { size: "xlarge" },
            style: {
              fontSize: 18,
              fontWeight: 600,
              padding: "0.8rem 1.6rem",
            },
          },
          {
            props: { variant: "contained-inverted" },
            style: {
              backgroundColor: baseTheme.palette.primary.light,
            },
          },
        ],
      },
      MuiDialog: {
        defaultProps: {},
        styleOverrides: {
          paper: {
            borderWidth: 0,
            boxShadow: shadows[10],
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            fontWeight: 600,
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: "1rem 1.5rem",
            justifyContent: "flex-start",
          },
        },
      },
      MuiFilledInput: {
        variants: [
          {
            props: { color: "secondary" },
            style: {
              "--focus-color": baseTheme.palette.secondary.main,
              "& .MuiInputLabel-root": {
                color: "black !imporant",
              },
            },
          },
        ],
        styleOverrides: {
          root: {
            border: "1px solid rgba(0, 0, 0, 0.2)",
            overflow: "hidden",
            borderRadius: 6,
            backgroundColor:
              baseTheme.palette.mode === "light"
                ? baseTheme.palette.grey[100]
                : baseTheme.palette.grey[800],
            transition: baseTheme.transitions.create([
              "border-color",
              "background-color",
              "box-shadow",
            ]),
            "--focus-color": baseTheme.palette.primary.main,
            "&::before": {
              display: "none",
            },
            "&::after": {
              display: "none",
            },
            "&:hover": {
              backgroundColor:
                baseTheme.palette.mode === "light"
                  ? "white"
                  : baseTheme.palette.divider,
            },
            "&.Mui-focused": {
              backgroundColor:
                baseTheme.palette.mode === "light"
                  ? "white"
                  : baseTheme.palette.divider,
              boxShadow: `var(--focus-color) 0 0 0 3px`,
              borderColor: "var(--focus-color)",
            },
          },
        },
      },
      MuiFormControl: {
        defaultProps: {
          variant,
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            "& ::placeholder": {
              fontSize: 14,
            },
          },
        },
      },
      MuiInputLabel: {
        defaultProps: {
          variant,
        },
        styleOverrides: {
          root: {
            fontSize: 14,
            fontWeight: 500,
            top: 2,
          },
        },
      },
      MuiMenu: {
        defaultProps: {
          elevation: 4,
        },
        styleOverrides: {
          paper: {
            boxShadow: baseTheme.shadows[6],
          },
        },
      },
      MuiPaper: {
        defaultProps: {
          variant: "outlined",
        },
      },
      MuiSelect: {
        defaultProps: {
          variant,
        },
      },
      MuiSkeleton: {
        styleOverrides: {
          rectangular: {
            borderRadius: baseTheme.shape.borderRadius,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: "none",
            letterSpacing: 0,
            fontWeight: 600,
            paddingTop: 16,
            paddingBottom: 16,
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          indicator: {
            height: 5,
            borderTopLeftRadius: 100,
            borderTopRightRadius: 100,
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            "th&": {
              fontWeight: 700,
            },
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          variant,
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
            fontWeight: 500,
            paddingLeft: "0.8rem",
            paddingRight: "0.8rem",
            letterSpacing: 0,
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            marginLeft: 0,
            marginRight: 0,
            marginTop: "0.5rem",
            letterSpacing: "0.02em",
            fontWeight: 600,
          },
        },
      },
    },
  },
  baseTheme
)
