import { z, ZodIssueCode, ZodErrorMap } from "zod"

/**
 * Czech plural forms.
 *
 * @see {@link https://unicode-org.github.io/cldr-staging/charts/latest/supplemental/language_plural_rules.html#cs}
 */
interface PluralForms {
  one: string // 1 dne
  few: string // 2–4 dny
  many: string // 1,5 dne
  other: string // 5 dní
}

/**
 * Determine if number is an integer.
 */
function isInteger(n: number): boolean {
  return n % 1 === 0
}

/**
 * Choose correct czech plural form according to number.
 */
function plural(n: number, pluralForms: PluralForms) {
  if (n === 1) {
    return pluralForms.one
  }

  if (n === 2 || n === 3 || n === 4) {
    return pluralForms.few
  }

  if (isInteger(n)) {
    return pluralForms.many
  }

  return pluralForms.other
}

/**
 * Customize Zod validation messages.
 *
 * [Zod error handling reference](https://github.com/colinhacks/zod/blob/master/ERROR_HANDLING.md)
 * [Default error map](https://github.com/colinhacks/zod/blob/master/src/ZodError.ts#L259)
 */
const errorMap: ZodErrorMap = (issue, ctx) => {
  switch (issue.code) {
    case ZodIssueCode.invalid_type:
      if (issue.received === "undefined") {
        return { message: "Toto pole je povinné" }
      }

      if (issue.expected === "integer") {
        return { message: "Vyplňte celé číslo" }
      }

      if (issue.expected === "number") {
        return { message: "Vyplňte číslo" }
      }

      return { message: "Neplatná hodnota" }

    case ZodIssueCode.too_small:
      if (issue.type === "array") {
      }

      if (issue.type === "string") {
        return {
          message: `Název musí mít ${
            issue.inclusive ? `alespoň` : `více než`
          } ${issue.minimum} ${plural(issue.minimum, {
            one: "znak",
            few: "znaky",
            many: "znaků",
            other: "znaku",
          })}`,
        }
      }

      if (issue.type === "number") {
        return {
          message: `Číslo musí být ${
            issue.inclusive ? `větší nebo rovno` : `větší než`
          } ${issue.minimum}`,
        }
      }

      return { message: "Neplatná hodnota" }
  }

  return { message: ctx.defaultError }
}

z.setErrorMap(errorMap)
