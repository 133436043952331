import React from "react"

export type Role = "employer" | "employee"

const RoleContext = React.createContext<Role>("employer")

export function useRole(): Role {
  return React.useContext(RoleContext)
}

export function RoleProvider({ children }: { children: React.ReactNode }) {
  const role: Role = "employer"
  return <RoleContext.Provider value={role}>{children}</RoleContext.Provider>
}
